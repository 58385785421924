<header>
    <h1>Ross C. Bovenkerk</h1>
    <a href="http://www.rossbovenkerk.com" target="_blank">www.RossBovenkerk.com</a> - <a target="_blank" href="https://www.linkedin.com/in/ross-bovenkerk/">https://www.linkedin.com/in/ross-bovenkerk/</a>
</header>

<section>
<h2>Professional Experience</h2>

<h3>CSL Behring				King of Prussia, Pennsylvania,				2009-Present</h3>
<h4>Sr. Manager, Web Operations 									July 2019 to Present</h4>
<ul>
<li>Act as Operations Manager to oversee projects and timelines, leveraging tools such as MS Teams and Project</li>
<li>Develop policy and procedures to create consistency across development teams, platforms, and websites</li>
<li>Created and operate an analytics strategy for use across all digital assets using Google Analytics and Data Studio</li>
</ul>


<h4>Manager, Web Operations 									April 2018 to June 2019</h4>
<ul>
<li>Oversaw operations to ensure projects are completed and team members are engaged appropriately</li>
<li>Developed an operations plan to oversee site health, including accessibility, SEO, compliance, and compatibility</li>
</ul>


<h4>Sr. Systems Analyst &amp; Operations Lead 								Sept. 2011 to April 2018</h4>
<ul>
<li>Designed, developed, and maintained websites and web applications using ASP.NET MVC, C#, SQL Server 2014, AJAX, CSS 3, HTML 5, and Javascript/jQuery. Act as Business Analyst to gather requirements and build to spec</li>
<li>Oversaw work allocation and day-to-day operations for the web team by working with customers, team members, and management to set priorities and responsibilities. Escalated issues as needed</li>
</ul>


<h4>Systems Analyst / Sr. Systems Analyst 								Sept 2009 to Sept 2011</h4>
<ul>
<li>Designed, developed, and maintained product and disease awareness websites. Shared support tasks on over 75 websites in many different web technologies such as ASP.NET, WordPress, PHP, HTML, and Java</li>
<li>Worked closely with business groups and external agencies to deliver large sites</li>
</ul>


<h3>WareSoft Solutions			King of Prussia, Pennsylvania,				2008-2009</h3>
<h4>Solutions Architect</h4>
<ul>
<li>Developed and maintained web applications and websites for pharmaceutical companies including CSL Behring, Wyeth, and Sanofi-Aventis. Accepted position offer from CSL Behring</li>
<li>Supported projects in Classic ASP, ASP.NET 1.1/2,0/3.5, SQL Server 2005, Oracle 9i, FatWire Content Server 6, VB.NET, C#, XHTML Strict, CSS, XML, JavaScript, and AJAX (jQuery)</li>
</ul>


<h3>Allstate Insurance Company 		Malvern, Pennsylvania, 					2004-2007</h3>
<h4>Web Application Developer</h4>
<ul>
    <li>Developed internal web applications using ASP.NET 1.x, 2.0, C#, VB.NET, and SQL Server 2000/2005. Received two “Team Success Awards” for work on marketing initiatives</li>
</ul>

<h3>First National Bank of Chester County	West Chester, Pennsylvania,				2002</h3>
<h4>Technical Support Center Specialist Intern</h4>

<h3>Data Matrix Inc				West Chester, Pennsylvania,				1997-1998</h3>
<h4>Junior Web Developer</h4>

<h2>Education and Certifications</h2>
<p>
MS in Information Systems,			Drexel University			2003-2006, 3.80 GPA<br />
BS in Information Science and Technology,	Pennsylvania State University		2000-2002, 3.66 GPA<br />
High School Diploma,				Henderson High School			1994-1998
</p>
<p>
<b>Microsoft:</b> MCSD: Web Applications, MCSE: Security Windows 2000, MCSA: Windows 2000, MCP<br />
<b>CompTIA:</b> Security+, Network+, A+
</p>
</section>