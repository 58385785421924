import { Window } from './window';

export const WINDOWS: Window[] = [
    { id: 1, type: 'doc',  title: 'Resume.doc', content: 'xxx' },
    { id: 2, type: 'link', title: 'LinkedIn', content: 'yyy' },
    { id: 3, type: 'notepad', title: 'Notepad', content: 'zzz' },

    { id: 4, type: 'link', title: 'LinkedIn', content: 'yyy' },
    { id: 5, type: 'kazaa', title: 'Kazaa', content: 'yyy' },
 /*   { id: 6, type: 'link', title: 'LinkedIn', content: 'yyy' },
    { id: 7, type: 'link', title: 'LinkedIn', content: 'yyy' },
    { id: 8, type: 'link', title: 'LinkedIn', content: 'yyy' },
    { id: 9, type: 'link', title: 'LinkedIn', content: 'yyy' }
*/

];
