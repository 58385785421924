<div class="startmenu" *ngIf="showStart">
    <div class="header"><img src="/assets/chess.jpg" /><span>Ross Bovenkerk</span></div>
    <div class="app-container">
        <div class="app-container-shortcuts">

        </div>
        <div class="app-container-folders">

        </div>
    </div>
    <div class="footer">
      <div class="buttons">
        <img src="/assets/lock.png" />
        Log Off
        <img src="/assets/power.png" />
        Power
      </div>
    </div>

</div>
