
<app-bsod></app-bsod>

<app-background>
  <app-shortcut></app-shortcut>
  <app-window></app-window>
  <template #windowContainer></template>
  <app-taskbar></app-taskbar>
</app-background>

<router-outlet></router-outlet>
