<div class="wallpaper">

    <app-shortcut (childEvent)="launchApplication($event)"></app-shortcut>

    <template #windowContainer></template>




    <app-taskbar></app-taskbar>
</div>
